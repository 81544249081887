<template>
  <v-container fluid>
    <v-row
      align="center"
      justify="center"
      no-gutters
    >
      <v-col
        cols="auto"
      >
        <v-form
          ref="loginForm"
          v-model="valid"
          @submit.prevent="login"
        >
          <material-card
            color="primary"
            :title="title"
            min-width="360"
          >
            <v-card-text class="text-center">
              <img
                id="logo"
                src="/img/arkstone-logo.svg"
                height="100"
                class="mt-2 mb-4"
              >
            </v-card-text>
            <template v-if="!['facility','facilities','provider','providers','clients'].includes(subdomain)">
              <v-divider xclass="my-4" />
              <v-card-text class="text-center">
                <v-row no-gutters>
                  <v-col
                    v-for="provider in socialButtons"
                    :key="provider.name"
                    class="px-1"
                  >
                    <v-btn
                      :color="provider.color"
                      dark
                      ripple
                      block
                      @click="socialLogin(provider.name.toLowerCase())"
                    >
                      <v-icon
                        left
                        class="ml-n2 mr-0"
                        v-text="provider.icon"
                      />
                      <span style="width:85%">{{ $t('Log in with') }} {{ provider.name }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </template>
            <template v-if="['provider','providers'].includes(subdomain)">
              <v-divider xclass="my-4" />
              <v-card-text
                class="text-center text-h5 branded"
                style="font-family: Montserrat !important; font-weight: 600;"
              >
                Provider Portal
              </v-card-text>
            </template>
            <v-divider />
            <v-card-text class="pt-6 pb-0">
              <v-alert
                transition="scale-transition"
                :value="alert != false"
                :type="alert.type"
              >
                {{ alert.message }}
              </v-alert>
              <v-text-field
                v-model="form.email"
                :label="$t('email')"
                name="email"
                type="email"
                required
                prepend-inner-icon="mdi-email-outline"
                :rules="rules.email"
                outlined
              />
              <v-text-field
                v-model="form.password"
                :label="$t('password')"
                name="password"
                type="password"
                required
                prepend-inner-icon="mdi-fingerprint"
                :rules="rules.password"
                class="pb-n3 mb-n3"
                outlined
              />
              <v-checkbox
                v-model="staySignedIn"
                label="Keep me signed in on this device"
                class="text-caption"
                dense
              />
              <!--
                    <v-checkbox
                      v-model="rememberMe"
                      label="Remember me on this device"
                      class="text-caption"
                      dense
                    />
                  -->
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                ripple
                block
                dark
                type="submit"
                :loading="loading"
              >
                {{ $t('Sign In') }}
              </v-btn>
            </v-card-actions>
            <v-col class="text-right text-caption">
              <router-link
                to="/password"
                dense
              >
                {{ $t('Forgot Password') }}
              </router-link>
            </v-col>
          </material-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import Bugsnag from '@bugsnag/js';
import * as Sentry from "@sentry/browser";

  export default {
    data () {
      return {
        valid: false,
        loading: false,
        alert: false,
        form: {
          email: this.remember?.email,
          password: null,
        },
        staySignedIn: false,
        rememberMe: this.remember,
        rules: {
          email: [
            v => !!v || 'Please enter your e-mail',
            v => /.+@.+/.test(v) || 'Please enter a valid e-mail address',
          ],
          password: [
            v => !!v || 'Please enter your password',
          ],
        },
        oauthParams: {},
        socialButtons: [
          {
            name: 'Google',
            icon: 'fa-brands fa-google',
            color: '#4285F4',
          },
          // {
          //   name: 'LinkedIn',
          //   icon: 'fa-brands fa-linkedin',
          //   color: '#2867b2',
          // },
        ],
      }
    },
    computed: {
      title () {
        var greeting
        const hour = new Date().getHours()
        if (hour >= 5 && hour < 12) {
          greeting = 'Good morning'
        } else if (hour >= 12 && hour < 17) {
          greeting = 'Good afternoon'
        } else if ((hour >= 17 && hour <= 23) || hour < 5) {
          greeting = 'Good evening'
        }
        return this.remember?.name ? `${greeting}, ${this.remember.name}` : null
      },
      remember () {
        return JSON.parse(this.$auth.remember()) || null
      },
    },
    mounted () {
      window.location.hash.substr(1).split('&').forEach(param => {
        const pair = param.split('=')
        this.oauthParams[pair[0]] = pair[1]
      })
      if (!this.oauthParams.access_token && this.$route.query.code) {
        this.oauthParams.access_token = this.$route.query.code
      }
      if (this.oauthParams.access_token && this.$route.params.type) {
        this.loading = true
        this.$auth.oauth2(this.$route.params.type, {
          url: 'auth/social/' + this.$route.params.type,
          code: true,
          state: this.oauthParams.state,
          data: this.oauthParams,
        })
          .then(() => {
            this.setLoggingDetails(this.$route.params.type)
            this.$router.push(this.$auth.redirect() ? this.$auth.redirect().from : { name: 'Dashboard' })
          }, () => {
            this.loading = false
          })
      }
    },
    methods: {
      login () {
        if (this.valid) {
          this.loading = true
          this.$auth.login({
            data: this.form,
            staySignedIn: this.staySignedIn,
            redirect: this.$auth.redirect() ? this.$auth.redirect().from : { name: 'Dashboard' },
          })
            .then(() => {
              if (this.rememberMe || this.staySignedIn) {
                this.$auth.remember(JSON.stringify({
                  name: this.$auth.user().first_name,
                  email: this.$auth.user().email,
                }))
              } else {
                this.$auth.unremember()
              }
              this.setLoggingDetails('email')
            }, (error) => {
              this.loading = false
              if (error.response) {
                switch (error.response.status) {
                  case 401:
                    this.alert = {
                      type: 'error',
                      message: 'The email address or password you entered is not recognized',
                    }
                    break
                  case 403:
                    this.alert = {
                      type: 'error',
                      message: 'Your email address is not verified',
                    }
                    break
                  default:
                    this.alert = {
                      type: 'error',
                      message: "We're having trouble logging you in. Please try again later.",
                    }
                }
              } else {
                this.alert = {
                  type: 'error',
                  message: "We're having trouble logging you in. Please try again later.",
                }
              }
          })
        } else {
          this.$refs.loginForm.validate()
        }
      },
      socialLogin (provider) {
        this.$auth.oauth2(provider, {
          staySignedIn: this.staySignedIn,
          code: false,
        //   window: {
        //     name: '_arkstone_social_login',
        //     specs: {
        //       width: '450',
        //       height: '600',
        //     },
        //     replace: true,
        //   },
        })
      },
      setLoggingDetails (method) {
        // LogRocket
        this.$LogRocket.identify(this.$auth.user().uuid, {
          name: this.$auth.user().first_name + ' ' + this.$auth.user().last_name,
          email: this.$auth.user().email,
          roles: this.$auth.user().roles,
          avatar: this.$auth.user().avatar,
        })

        // Bugsnag
        Bugsnag.setUser(
          this.$auth.user().uuid,
          this.$auth.user().email,
          this.$auth.user().first_name + ' ' + this.$auth.user().last_name,
        )

        Bugsnag.beforeNotify = function (data) {
          data.metaData.sessionURL = this.$LogRocket.sessionURL;
          return data;
        }

        // Sentry
        this.$LogRocket.getSessionURL(sessionURL => {
          this.$Sentry.configureScope(scope => {
            scope.setExtra("sessionURL", sessionURL);
          });
        });

        Sentry.setUser({
          id: this.$auth.user().uuid,
          username: this.$auth.user().first_name + ' ' + this.$auth.user().last_name,
          email: this.$auth.user().email,
        });

        // Google Analytics
        this.$gtag.event('login', {
          method: method,
        })
        this.$gtag.set({
          user_id: this.$auth.user().uuid,
        })
        this.$gtag.event('LogRocket', {
          event_category: 'LogRocket',
          event_action: this.$LogRocket.sessionURL,
        })
      },
    },
  }
</script>

<style scoped>
background {
  background: #69bee7;
  height: 100%;
}
#logo {
  margin: 20px;
}
</style>
